$(window).on('load', function(){
   // loadBannerLapsusOnly()
})
function loadBannerLapsusOnly(){
    actionWebApiRestrict('/v1/stream/article/lapsus', {'limit':5}, 'GET').done(function(json){
        if(json.status == 600){
            var appendText = '';
            appendText += drawBanners(json.data, "lapsus");
            $('#banner-container').html(appendText);
            slickCarousell();
        }
    })
}
function loadBannerWithCollection() {
  var appendText = '';
  $.when(loadCollection(), loadLapsus()).done(function(collection, lapsus){
    if(collection[0].status == 600){appendText += drawBanners(collection[0].data, "collection");}
    if(lapsus[0].status == 600){appendText += drawBanners(lapsus[0].data, "lapsus");}
    $('#banner-container').html(appendText);
    slickCarousell();
  })
}
function loadLapsus(){
    return actionWebApiRestrict('/v1/stream/article/lapsus', {'limit':3}, 'GET');
}
function loadCollection(){
    return actionWebApiRestrict('/v1/collection/latest', {'limit':2}, 'GET');
}
function drawBanners(banners, bannerType){
  var appendText = '';
  $.each(banners, function(idx, ban) {
    var setImg = '';
    var setUrl = '';
    var setUtm = '';
    if(bannerType == "lapsus"){
        setUtm = "?utm_campaign=lapsus&utm_medium=lapsus-banner&utm_source=website&utm_content="+slugify(ban.title)+"&utm_term=";
        setUrl = ban.url+setUtm;
        if($(window).width() > 767){
            setImg = ban.banner_image_web.original
        }else{
            setImg = ban.banner_image_mweb.original
        }
    }else if(bannerType == "collection"){
        setUrl = ban.slug_url;
        if($(window).width() > 767){
            setImg = ban.banner_image_web
        }else{
            setImg = ban.banner_image_mweb
        }
    }
    appendText += '<a href="'+setUrl+'" >';
    appendText += '<img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" data-original="'+setImg+'" alt="cover banner" class="lazy-lapsus-cover">';
    appendText += '</a>';
  })
  return appendText;
}

function slickCarousell(){
  var item_length = $('.slider > div').length - 1;
  $(".home-carousell-banner-container").slick({
    dots: true,
    lazyLoad: 'ondemand', // ondemand progressive anticipated
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000
  });
  $('.slick-prev').html("")
  $('.slick-next').html("")
  $('.slick-dots button').html("")
  $('.lazy-lapsus-cover').lazyload().trigger("appear")
}
